import type { LocationQuery } from 'vue-router'
import type { FilterOptions, Order, Supplier } from '~/types'

interface FrequentOrderItem {
  name: string
  handle: string
  supplier: Supplier
  lastOrderedQuantity: number
  totalOrderQuantity: number
  loading?: boolean
}

interface State {
  list: Array<any>
  specialOffersList: Array<any>
  isLoading: boolean
  lastOrderedCount: number
  totalOrders: number
  filterOptions: FilterOptions
  frequentOrders: FrequentOrderItem[]
}

interface OrderHistoryResponse {
  totalOrders: number
  orders: Order[]
  filterOptions: FilterOptions
}

export const useOrderHistoryStore = defineStore('order-history', {
  // convert to a function
  state: (): State => ({
    list: [],
    specialOffersList: [],
    isLoading: false,
    lastOrderedCount: 0,
    totalOrders: 0,
    filterOptions: {},
    frequentOrders: [],

  }),
  getters: {},
  actions: {
    async getLastOrderedCount(): Promise<number | undefined> {
      const { $i18n, $storeErrorLog } = useNuxtApp()
      try {
        this.isLoading = true
        const { data } = await useFetch<number>('/api/v2/orders-count')
        if (data.value == null)
          throw new Error('Failed to load order-count')
        this.lastOrderedCount = data.value
        this.totalOrders = data.value
        this.isLoading = false
        return data.value
      }
      catch (error) {
        $storeErrorLog(
          'stores/order-history.ts',
          'Could not get last ordered count',
          $i18n.t('errors.page.unknown.subtitle'),
          error,
        )
        this.isLoading = false
      }
    },

    async getLastOrders(
      query: LocationQuery | Record<string, any>,
      replaceList = false,
    ): Promise<{ status: number, error?: any }> {
      if (this.isLoading) {
        return {
          status: 200,
        }
      }
      const { $i18n, $storeErrorLog } = useNuxtApp()
      const { locationQueryToUrlSearchParams } = useHelper()
      const URLSearchParams = locationQueryToUrlSearchParams(query as Record<string, string>)
      try {
        this.isLoading = true
        const data = await $fetch<OrderHistoryResponse>(`/api/v2/orders?${URLSearchParams.toString()}`)

        if (data == null)
          throw new Error('Failed to load orders')

        this.totalOrders = data.totalOrders
        this.filterOptions = data.filterOptions
        if (replaceList)
          this.list = []
        this.list.push(...data.orders)
        this.isLoading = false
        return {
          status: 200,
        }
      }
      catch (error: any) {
        $storeErrorLog(
          'stores/order-history.ts',
          'Could not get last ordered count',
          $i18n.t('errors.page.unknown.subtitle'),
          error,
        )
        this.isLoading = false
        return {
          status: error.statusCode,
          error,
        }
      }
    },

    async postOrderRequest(requestType: string, handle: string): Promise<void> {
      const { $storeErrorLog, $i18n } = useNuxtApp()
      const { ORDER_HISTORY_REQUEST_TYPES } = useConstants()
      try {
        let url = `/api/v2/orders/${handle}`

        switch (requestType) {
          case ORDER_HISTORY_REQUEST_TYPES.INVOICE:
            url = `${url}/request-invoice`
            break
          case ORDER_HISTORY_REQUEST_TYPES.ORDER_STATUS:
            url = `${url}/request-order-status`
            break
          case ORDER_HISTORY_REQUEST_TYPES.ORDER_CANCELLATION:
            url = `${url}/request-order-cancellation`
            break
        }
        await $fetch(url, { method: 'POST' })
      }
      catch (error: any) {
        $storeErrorLog(
          'stores/order-history.ts',
          `Could not post request for ${requestType}`,
          $i18n.t('errors.page.unknown.subtitle'),
          error,
        )
      }
    },

    async getFrequentOrders(handle: string) {
      try {
        this.isLoading = true
        const { data, error } = await useFetch<any>(`/api/easyApi/customers/${handle}/order-history/frequent`)

        if (error.value) {
          throw error.value
        }
        if (data.value) {
          this.frequentOrders = data.value.items.map((item: FrequentOrderItem) => ({
            name: item.name,
            handle: item.handle,
            lastOrderedQuantity: item.lastOrderedQuantity,
            totalOrderQuantity: item.totalOrderQuantity,
            loading: false,
            supplier: item.supplier,
          }))
        }
        else {
          throw new Error(`Unexpected response format: ${data.value}`)
        }
      }
      catch (error) {
        console.error('Failed to fetch frequent orders:', error)
      }
      this.isLoading = false
    },

    async getOrderDetails(handle: string): Promise<any | undefined> {
      const { $storeErrorLog, $i18n } = useNuxtApp()
      try {
        this.isLoading = true
        const { data, error } = await useFetch<any>(`/api/easyApi/order-history?handle=${handle}`)

        if (error.value)
          throw new Error(`Failed to fetch order details for handle: ${handle}`)

        this.isLoading = false
        return data.value
      }
      catch (error: any) {
        $storeErrorLog(
          'stores/order-history.ts',
          `Could not fetch order details for handle: ${handle}`,
          $i18n.t('errors.page.unknown.subtitle'),
          error,
        )
        this.isLoading = false
      }
    },

    // easily reset state using `$reset`
    reset() {
      this.$reset()
    },
  },
})
